import { ChainId } from '@crosswise-finance1/sdk-v2'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  // const chainId = '97'
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}
export const getChainLinkAddress = () => {
  return '0xC0bcf8297992EE0DC8785e14733230F46069Bb7F'
}
export const getCrssAddress = () => {
  return getAddress(tokens.crss.address)
}
export const getSCrssAddress = () => {
  return getAddress(tokens.scrss.address)
}
export const getXCrssAddress = () => {
  return getAddress(tokens.xcrss.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}

export const getVaultAddress = () => {
  return getAddress(addresses.crssVault)
}


export const getCrssReferralAddress = () => {
  return getAddress(addresses.crssReferral)
}
export const getVestingAddress = () => {
  return getAddress(addresses.vesting)
}

export const getPriceConsumerAddress = () => {
  return getAddress(addresses.priceConsumer)
}
export const getCompensationAddress = () => {
  return getAddress(addresses.compensation)
}
export const getCrossFactoryAddress = () => {
  return getAddress(addresses.crossFactory)
}
