import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
// import { poolsConfig } from 'config/constants'
// import { PoolCategory } from 'config/constants/types'

// Addresses
import {
  // getAddress,
  getCrssAddress,
  getMasterChefAddress,
  getCrssReferralAddress,
  getMulticallAddress,
  getPriceConsumerAddress,
  getChainLinkAddress,
  getCompensationAddress,
  getSCrssAddress,
  getVestingAddress,
  getCrossFactoryAddress
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import masterChef from 'config/abi/masterchef.json'
import crssReferralAbi from 'config/abi/crssReferral.json'
import vestingAbi from 'config/abi/vesting.json'
import crssTokenAbi from 'config/abi/crssToken.json'
import sCrssTokenAbi from 'config/abi/sCrssToken.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import priceConsumerAbi from 'config/abi/priceConsumer.json'
import crossPairAbi from 'config/abi/crossPair.json'
import ChainLinkAbi from 'config/abi/chainlinkcontrol.json'
import compensationAbi from 'config/abi/compensation.json'
import crossFactoryAbi from 'config/abi/crossFactory.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getCrossPairContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(crossPairAbi, address, signer)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}

export const getCrssContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(crssTokenAbi, getCrssAddress(), signer)
}

export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}

export const getCrssTokenContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(crssTokenAbi, getCrssAddress(), signer)
}
export const getSCrssTokenContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(sCrssTokenAbi, getSCrssAddress(), signer)
}

export const getCrssReferralContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(crssReferralAbi, getCrssReferralAddress(), signer)
}
export const getVestingContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(vestingAbi, getVestingAddress(), signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getChainLinkContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ChainLinkAbi, getPriceConsumerAddress(), signer)
}

export const getPriceConsumerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(priceConsumerAbi, getPriceConsumerAddress(), signer)
}

export const getCompensationContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(compensationAbi, getCompensationAddress(), signer)
}

export const getCrossFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(crossFactoryAbi, getCrossFactoryAddress(), signer)
}
