import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getBep20Contract,
  getLpContract,
  getCrssContract,
  getMasterchefContract,
  getCrssReferralContract,
  getChainLinkContract,
  getCrssTokenContract,
  getPriceConsumerContract,
  getCompensationContract,
  getSCrssTokenContract,
  getVestingContract,
  getCrossFactoryContract
} from 'utils/contractHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId } from '@crosswise-finance1/sdk-v2'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import { WBNB } from '../config/constants/tokens'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../config/constants/multicall'
import { getContract } from '../utils'

/**
 * Helper hooks to get specific contracts (by ABI)
 */



export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

export const useLPToken = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLpContract(address, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */


export const useCake = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCrssContract(library.getSigner()), [library])
}

export const useMasterchef = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMasterchefContract(library.getSigner()), [library])
}
export const useCompensationContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCompensationContract(library.getSigner()), [library])
}


export const useCrssTokenContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCrssTokenContract(library.getSigner()), [library])
}

export const useSCrssTokenContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSCrssTokenContract(library.getSigner()), [library])
}

export const useVestingContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getVestingContract(library.getSigner()), [library])
}

export const useCrssReferralContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCrssReferralContract(library.getSigner()), [library])
}


export const useChainlinkOracleContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getChainLinkContract(library.getSigner()), [library])
}


export const usePriceConsumerContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPriceConsumerContract(library.getSigner()), [library])
}


export const useCrossFactoryContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCrossFactoryContract(library.getSigner()), [library])
}


// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error: any) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WBNB[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
