import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import {
  farmsConfig,
  // poolsConfig
} from 'config/constants'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  // fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserInfo,
  // fetchFarmUserStakedBalances,
  // fetchFarmUserOption,
} from './fetchFarmUser'
import {CompensationState, Compensation } from '../types'
import fetchPublicCompensationData from './fetchPublicCompensationData'

const noAccountCompensationConfig =  {
  vesting: '0',
  claimable: '0',
  withdrawable: '0',
  claimed: '0',
  totalCrssOwed: '0',
  totalUserWithdrawn: '0'
}

const initialState: CompensationState = { data: noAccountCompensationConfig as Compensation, loadArchivedCompensationData: false, userDataLoaded: false }

export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchCompensationPublicDataAsync = createAsyncThunk<Compensation, {account: string}>(
  'farms/fetchCompensationPublicDataAsync',
  async ({account}) => {
    const compensationHelper = await fetchPublicCompensationData(account);
    return compensationHelper
    // return farmsWithPrices
  },
)

interface FarmUserDataResponse {
  pid: number
  isLock: boolean
  allowance: string
  tokenBalance: string
  stakedBalance: string
  pendingCrss: string
  accumulatedRewards: string
  vestingList: any
  earnings: string
  compounding: boolean
  isAuto: boolean
  isVest: boolean
  behavior: any[]
}

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'farms/fetchFarmUserDataAsync',
  async ({ account, pids }) => {
    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
  
    const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)        
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)    
    const userFarmInfo = await fetchFarmUserInfo(account, farmsToFetch)
    return userFarmAllowances.map((farmAllowance, index) => {
      console.log("farmAllowance", farmAllowance)
      // const userEarnings = new BigNumber(userFarmInfo[index].pendingCrss)
      //   .plus(new BigNumber(userFarmInfo[index].accumulatedRewards))
      //   .plus(new BigNumber(userFarmInfo[index].vestingRewards))
      //   .toJSON()
      //   console.log("inedx", userFarmInfo[index])
      return {
        pid: farmsToFetch[index].pid,
        isLock: farmsToFetch[index].isLock || false,
        allowance: farmAllowance,
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userFarmInfo[index]?.stakedBalance,
        // accumulatedRewards: userFarmInfo[index]?.accumulatedRewards,
        vestingRewards: userFarmInfo[index]?.vestingRewards,
        compounding: userFarmInfo[index]?.compounding,
        // vestingList: userFarmInfo[index]?.vestingList,
        // depositList: userFarmInfo[index]?.depositList,
        pendingCrss: userFarmInfo[index]?.pendingCrss,
        // earnings: userEarnings,
        // isAuto: userFarmInfo[index].autoCompound,
        // isVest: userFarmInfo[index].isVesting,
        // behavior: userFarmInfo[index].behavior,
      }
    })
  },
)

export const compensationSlice = createSlice({
  name: 'Compensation',
  initialState,
  reducers: {
    setLoadArchivedCompensationData: (state, action) => {
      const loadArchivedCompensationData = action.payload
      state.loadArchivedCompensationData = loadArchivedCompensationData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchCompensationPublicDataAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedCompensationData } = compensationSlice.actions

export default compensationSlice.reducer
