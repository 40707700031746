import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 4 farms (PID 0, 1, 2, 3) should always be at the top of the file.
   */
  /* {
     pid: 0,
     lpSymbol: 'CRSS-BNB LP',
     lpAddresses: {
       97: '0x43A702EB9096145654FF92bd2E1c751F3Dca1411',
       56: '0xb5d85cA38a9CbE63156a02650884D92A6e736DDC',
     },
     token: tokens.crss,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 1,
     lpSymbol: 'CRSS-BNB LP Lock',
     lpAddresses: {
       97: '0x43A702EB9096145654FF92bd2E1c751F3Dca1411',
       56: '0xb5d85cA38a9CbE63156a02650884D92A6e736DDC',
     },
     token: tokens.crss,
     quoteToken: tokens.wbnb,
     isLock: true,
   }, */
  {
    pid: 0,
    lpSymbol: 'CRSS-BNB LP',
    lpAddresses: {
      97: '0x989638b4B3F4a935ee833dBE6498Ea9C55DB4510',
      56: '0xBDF7D0562A47c826e93e6A799f33f206c0B50e41',
    },
    token: tokens.crss,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'CRSS-BUSD LP',
    lpAddresses: {
      97: '0x79FED38dCd73fEEfCbE8A52355B30111e9aBe0c6',
      56: '0x2DD352199270a10DFf022EAeEa13F9Ae3b3323a2',
    },
    token: tokens.crss,
    quoteToken: tokens.busd,
  },
  {
    pid: 2,
    lpSymbol: 'sCRSS-BNB LP LOCK',
    lpAddresses: {
      97: '0xBB5dab2E98CA97e1fCA471629191F92F451c1137',
      56: '0xc108D0006E5a9F2a1928e718D2bA47a1ACE52510', 
    },
    token: tokens.scrss,
    quoteToken: tokens.wbnb,
    isLock: true,
  }, 
   {
     pid: 3,
     lpSymbol: 'BNB-BUSD LP',
     lpAddresses: {
       97: '0x95F729d078DD7656d9CC1936098188c113F985BD',
       56: '0x84Dcc53FaF4E04D298c63bF5259bb0Fe91354C80',
     },
     token: tokens.wbnb,
     quoteToken: tokens.busd,
   },
   {
     pid: 4,
     lpSymbol: 'BNB-ETH LP',
     lpAddresses: {
       97: '0x22C7E641B05F7403436bfCEd9626b10EEb98700e',
       56: '0x8e8e1473D321Acae34E3f7637fdE5a876d259DF5',
     },
     token: tokens.eth,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 5,
     lpSymbol: 'BNB-BTC LP',
     lpAddresses: {
       97: '0x037ECF5185630Fb6388403ED1dfb96217eB0F858',
       56: '0x767866F031A11689f3d5CCe42002f7A50d1b9Dc5',
     },
     token: tokens.btcb,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 6,
     lpSymbol: 'BNB-LINK LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0x523DAa4f4Aa778E1aDA6977A1401728A49D3A468',
     },
     token: tokens.link,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 7,
     lpSymbol: 'BNB-ADA LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0xc5378aeA81eFC9C0CeCc31EEbcaf20516D54E014',
     },
     token: tokens.eth,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 8,
     lpSymbol: 'BUSD-USDT LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0xb12F7a7B84B162E1E6e75D3C5eD0710F2FcbB32E',
     },
     token: tokens.usdt,
     quoteToken: tokens.busd,
   },
/*   {
     pid: 7,
     lpSymbol: 'CAKE-BNB LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0x0458498c2ccbbe4731048751896a052e2a5cc041',
     },
     token: tokens.cake,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 8,
     lpSymbol: 'ADA-BNB LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0xde0356a496a8d492431b808c758ed5075dd85040',
     },
     token: tokens.ada,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 9,
     lpSymbol: 'DOT-BNB LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0xcb7ad3af3ae8d6a04ac8eca9a77a95b2a72b06de',
     },
     token: tokens.dot,
     quoteToken: tokens.wbnb,
   },
   {
     pid: 10,
     lpSymbol: 'LINK-BNB LP',
     lpAddresses: {
       97: '0x045e7efe7139ed74cdc21b6f96468e7ce57ed4b3',
       56: '0x278d7d1834e008864cfb247704cf34a171f39a2c',
     },
     token: tokens.link,
     quoteToken: tokens.wbnb,
   },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
 /*  {
     pid: 445,
     lpSymbol: 'POTS-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0xF90BAA331Cfd40F094476E752Bf272892170d399',
     },
     token: tokens.pots,
     quoteToken: tokens.busd,
   },
   {
     pid: 397,
     lpSymbol: 'TUSD-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0x2e28b9b74d6d99d4697e913b82b41ef1cac51c6c',
     },
     token: tokens.tusd,
     quoteToken: tokens.busd,
   },
   {
     pid: 443,
     lpSymbol: 'BTT-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0xdcfbb12ded3fea12d2a078bc6324131cd14bf835',
     },
     token: tokens.btt,
     quoteToken: tokens.busd,
   },
   {
     pid: 442,
     lpSymbol: 'TRX-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0xb5d108578be3750209d1b3a8f45ffee8c5a75146',
     },
     token: tokens.trx,
     quoteToken: tokens.busd,
   },
   {
     pid: 441,
     lpSymbol: 'WIN-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0x6a445ceb72c8b1751755386c3990055ff92e14a0',
     },
     token: tokens.win,
     quoteToken: tokens.busd,
   }, */
]

export default farms
