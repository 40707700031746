import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import {
  farmsConfig,
  // poolsConfig
} from 'config/constants'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  // fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserInfo,
  // fetchFarmUserStakedBalances,
  // fetchFarmUserOption,
} from './fetchFarmUser'
import { FarmsState, Farm } from '../types'

const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  withdrawLock: '0',
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    vestingList: [],
    vestingRewards: ['0', '0'],
    earnings: ['0', '0'],
    accumulatedRewards: ['0', '0'],
    compounding: false,
    nextUnlock: '0',
    isAuto: false,
    isVest: false,
    behavior: [],
  },
}))

const initialState: FarmsState = { data: noAccountFarmConfig, loadArchivedFarmsData: false, userDataLoaded: false }

export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<Farm[], number[]>(
  'farms/fetchFarmsPublicDataAsync',
  async (pids) => {
    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
    // Add price helper farms
    const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig)
    const farms = await fetchFarms(farmsWithPriceHelpers)
    const farmsWithPrices = await fetchFarmsPrices(farms)
   
    // Filter out price helper LP config farms
    const farmsWithoutHelperLps = farmsWithPrices.filter((farm: Farm) => {
      return farm
    })
    return farmsWithoutHelperLps
    // return farmsWithPrices
  },
)

interface FarmUserDataResponse {
  pid: number
  isLock: boolean
  allowance: string
  tokenBalance: string
  stakedBalance: string
  pendingCrss: string
  accumulatedRewards: any
  vestingList: any
  earnings: any
  compounding: boolean
  nextUnlock: string
  isAuto: boolean
  isVest: boolean
  behavior: any[]
}

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'farms/fetchFarmUserDataAsync',
  async ({ account, pids }) => {
    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
  
    const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)        
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)    
    const userFarmInfo = await fetchFarmUserInfo(account, farmsToFetch)
    return userFarmAllowances.map((farmAllowance, index) => {
      console.log("farmAllowance", farmAllowance)
      // const userEarnings = new BigNumber(userFarmInfo[index].pendingCrss)
      //   .plus(new BigNumber(userFarmInfo[index].accumulatedRewards))
      //   .plus(new BigNumber(userFarmInfo[index].vestingRewards))
      //   .toJSON()
      //   console.log("inedx", userFarmInfo[index])
      return {
        pid: farmsToFetch[index].pid,
        isLock: farmsToFetch[index].isLock || false,
        allowance: farmAllowance,
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userFarmInfo[index]?.stakedBalance,
        accumulatedRewards: userFarmInfo[index]?.accumulatedRewards,
        vestingRewards: userFarmInfo[index]?.vestingRewards,
        compounding: userFarmInfo[index]?.compounding,
        earnings: userFarmInfo[index]?.earnings,
        // vestingList: userFarmInfo[index]?.vestingList,
        // depositList: userFarmInfo[index]?.depositList,
        pendingCrss: userFarmInfo[index]?.pendingCrss,
        // earnings: userEarnings,
        isAuto: userFarmInfo[index].autoCompound,
        nextUnlock: userFarmInfo[index].nextUnlock,
        // isVest: userFarmInfo[index].isVesting,
        // behavior: userFarmInfo[index].behavior,
      }
    })
  },
)

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((farm) => farm.pid === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions

export default farmsSlice.reducer
