import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import vestingABI from 'config/abi/vesting.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress, getVestingAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { BIG_ZERO } from 'utils/bigNumber'

export const AutoOption = [
  { autoCompound: false, isVesting: false },
  { autoCompound: true, isVesting: false },
  { autoCompound: true, isVesting: true },
  { autoCompound: false, isVesting: true },
]

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export interface IFarmUserInfo {
  stakedLp: number
  claimableRewards: number
  canWithdrawLocked: boolean
  compounding: boolean
  vestingTokens: any
  earnedTokens: any
  userVestingInstances: any
  vestedCrss: number
  vestedLpTokens: number
  nextUnlock: number
}

export const fetchFarmUserInfo = async (account: string, farmsToFetch: FarmConfig[]) => {
  console.log("fetch farm user info")
  const masterChefAddress = getMasterChefAddress()
  const vestingAddress = getVestingAddress()
  const userStateCalls = []
  const userGetEarned = []
  const userInfoCalls = []
  // const subPoolCrssCall = []
  const vestingListCall = []

  const tempFarms = [];

  farmsToFetch.map((farm, index) => {
    // userInfoCalls.push({
    //   address: masterChefAddress,
    //   name: 'userInfo',
    //   params: [farm.pid, account],
    // })
    // // subPoolCrssCall.push({
    // //   address: masterChefAddress,
    // //   name: 'getSubPooledCrss',
    // //   params: [farm.pid, account],
    // // })
    // vestingListCall.push({
    //   address: masterChefAddress,
    //   name: 'getVestList',
    //   params: [farm.pid, account],
    // })
    let i = index;

    tempFarms.forEach((item, j) => {
      if (item?.lpAddresses['56'] === farm?.lpAddresses['56']) {
        i = j;
      }
    })
    
    if (i === index) {
      tempFarms.push(farm);
    }

    userStateCalls.push({
      address: vestingAddress,
      // name: 'userInfo',
      name: 'userFarmPoolInfo',
      params: [account, i],
    })
    return true;
  })
  const rawUserState = await multicall(vestingABI, userStateCalls)
  // const rawUserInfo = await multicall(masterchefABI, userInfoCalls)

  // const rawSubPooledCrss = await multicall(masterchefABI, subPoolCrssCall)

  // const rawVestingList = await multicall(masterchefABI, vestingListCall)
  try {
    const parsedStakedBalances = rawUserState.map((userStateItem, index) => {
      console.log("userStateItem", userStateItem)
      const stakedLp = userStateItem.stakedLp
      const claimableRewards = userStateItem.claimableRewards
      const canWithdrawLocked = userStateItem.canWithdrawLocked
      const compounding = userStateItem.compounding
      const vestingCrss = userStateItem.vestingCrss
      const vestingLP= userStateItem.vestingLpTokens
      const withdrawnCrss = userStateItem.withdrawnCrss
      const withdrawnLP = userStateItem.withdrawnLpTokens
      const userVestingInstances = userStateItem.userVestingInstances
      const vestedCrss = userStateItem.vestedCrss
      const vestedLpTokens = userStateItem.vestedLpTokens
      const nextUnlock = userStateItem.nextUnlock
      const timeUntilWithdrawUnlocked = userStateItem.timeUntilWithdrawUnlocked
      // const userInfo = rawUserInfo[index]
      // const subPooledCrss = rawSubPooledCrss[index][0]
      // const vestingList = rawVestingList[index][0]

      // const parseBignumber = (value) => new BigNumber(value._hex).toJSON()
      // const depositList = userState.assets.depositList.map((depositListItem) => ({
      //   amount: parseBignumber(depositListItem.amount),
      //   depositAt: parseBignumber(depositListItem.depositAt),
      // }))

      // const pendingCrss = userState.behavior.pendingCrss.add(subPooledCrss.toAccumulate).add(subPooledCrss.toVest)
      // const { pendingCrss } = userState.behavior
      // let vestingRewards = BIG_ZERO
      // const vestingListResult = vestingList.map((listItem) => {
      //   vestingRewards = vestingRewards
      //     .plus(parseBignumber(listItem.principal))
      //     .minus(parseBignumber(listItem.withdrawn))
      //   return listItem && listItem.length
      //     ? {
      //         principal: new BigNumber(listItem.principal._hex).toJSON(),
      //         startTime: new BigNumber(listItem.startTime._hex).toJSON(),
      //         withdrawn: new BigNumber(listItem.withdrawn._hex).toJSON(),
      //       }
      //     : []
      // })

      return {
        // stakedBalance: new BigNumber(userState.amount._hex).toJSON(),
        // accumulatedRewards: new BigNumber(userState.accumulatedRewards._hex).toJSON(),
        // ...AutoOption[rawUserState.autoOption || 0],
        stakedBalance: new BigNumber(stakedLp._hex).toJSON(),
        accumulatedRewards: [new BigNumber(vestedCrss._hex).toJSON(), new BigNumber(vestedLpTokens._hex).toJSON()],
        pendingCrss: new BigNumber(claimableRewards._hex).toJSON(),
        earnings: [new BigNumber(withdrawnCrss._hex).toJSON(), new BigNumber(withdrawnLP._hex).toJSON()],
        // claimedRewards: new BigNumber(userInfo.accumulated._hex).toJSON(),
        vestingRewards: [new BigNumber(vestingCrss._hex).toJSON(), new BigNumber(vestingLP._hex).toJSON()],
        compounding,
        nextUnlock: new BigNumber(nextUnlock._hex).toJSON(),
        // // vestingRewards: new BigNumber(userState.assets.totalVest._hex).toJSON(),
        // vestingList: vestingListResult,
        // depositList,
        // behavior: [
        //   parseBignumber(pending),
        //   parseBignumber(pendingPerBlock),
        //   parseBignumber(rewardPayroll),
        //   parseBignumber({ _hex: '100000000000000' }),
        // ],
        // ...AutoOption[userState.assets.collectOption || 0],
      }
    })
    return parsedStakedBalances
  } catch (err: any) {
    console.error('here', err)
    return []
  }
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'stakedTokens',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'getUserState',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchFarmUserOption = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawOption = await multicall(masterchefABI, calls)
  const parsedOption = rawOption.map((auto) => {
    return [auto.isAuto, auto.isVest]
  })
  return parsedOption
}
