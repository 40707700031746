export default {
  masterChef: {
    97: '0x9bCe42AC417F8fe0eDFdB043b63bA0B942797D3B',
    56: '0x5a73dbdbF68F90E4DCe2dac99d3250E43e34450c',
  },
  compensation: {
    97: '0xD6BE3399c69F758F918c817380213F22c66F6b34',
    56: '0x56a090c0fd12e4DbcC60191BAE1c0a460176e708',
  },

  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },


  cakeVault: {
    56: '0x0E9b358cBc14c7a54441765438a0D37EdeF58cAF',
    97: '0xf5d562CdD2C4e7e3c1a98B7c9DA5Bb713e31a771',
  },

  crssVault: {
    56: '0x03EFc6CeC946Df53c7d59CDfc05e2a63cEfd32a4',
    97: '0xf5d562CdD2C4e7e3c1a98B7c9DA5Bb713e31a771',
  },
  crssReferral: {
    56: '0x5133d315fA94BbA9155BC7bcE4891923eF0228f6',
    97: '0x1d0A7c5809B1479dde4c710cbAB4A97775A1231d',
  },

  priceConsumer: {
    56: '0xf254C4d31240e33433Bb71a46D9d745c9AA63eba',
    97: '0x92ca9ca90732cd8e2b53df904dcfd60c0a2621f7',
  },
  vesting: {
    56: '0x779E97aF6a4b8af745Ed388e0959b316410a450A',
    97: '0x18C1178A5509B1DE5527B8D54203C4DBf14A27a0',
  },

  crossFactory: {
    56: '0xA70FF6cf5A330F9D4c697C27953D09B4518348A6',
    97: '0x3BF5795aD335Af0a515372C809CE2412d9031387',
  },
}
