import { FarmConfig } from 'config/constants/types'
import { Farm } from 'state/types'
import fetchFarmData from './fetchFarm'

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
  const tempFarms = [];
  let k = -1;
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig, index) => {
      let i = index
      tempFarms.forEach((item: any, j) => {
        if (item.lpAddresses['56'] === farmConfig.lpAddresses['56'].toString()) {
          i = j;
        }
      })

      if (i === index) {
        tempFarms.push(farmConfig)
        k++
      }
      const farm = await fetchFarmData(farmConfig as Farm, k)
      return farm
    }),
  )
  return data
}

export default fetchFarms
