import BigNumber from 'bignumber.js'
import compensationABI from 'config/abi/compensation.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getCompensationAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { Farm, SerializedBigNumber } from '../types'

type PublicCompensationData = {
  vesting: string
  claimable: string
  withdrawable: string
  claimed: string
  totalCrssOwed: string
  totalUserWithdrawn: string
}

const fetchCompensationData = async (account): Promise<PublicCompensationData> => {
  // Only make masterchef calls if farm has pid
  const [vesting, claimable, withdrawable, userCompensation, userWithdrawn] =
    account ? await multicall(compensationABI, [
      {
        address: getCompensationAddress(),
        name: 'getUserVesting',
        params: [account],
      },
      {
        address: getCompensationAddress(),
        name: 'getUserClaimable',
        params: [account]
      },
      {
        address: getCompensationAddress(),
        name: 'getUserWithdrawable',
        params: [account]
      },
      {
        address: getCompensationAddress(),
        name: 'getUserCompensation',
        params: [account]
      },
      {
        address: getCompensationAddress(),
        name: 'getUserWithdrawn',
        params: [account]
      }
    ])
      : [null, null, null, null, null]

  const vestingCrss = vesting ? new BigNumber(vesting[0]._hex).dividedBy(new BigNumber(10 ** 18)) : BIG_ZERO
  const claimableCrss = claimable ? new BigNumber(claimable[0]._hex).dividedBy(new BigNumber(10 ** 18)) : BIG_ZERO
  const withdrawableCrss = withdrawable ? new BigNumber(withdrawable[0]._hex).dividedBy(new BigNumber(10 ** 18)) : BIG_ZERO
  const claimedCrss = userCompensation ? new BigNumber(userCompensation[0].crssClaimed._hex).dividedBy(new BigNumber(10 ** 18)) : BIG_ZERO
  const totalCrss = userCompensation ? new BigNumber(userCompensation[0].totalCrssOwed._hex).dividedBy(new BigNumber(10 ** 18)) : BIG_ZERO
  const withdrawnCrss = userWithdrawn ? new BigNumber(userWithdrawn[0]._hex).dividedBy(new BigNumber(10 ** 18)) : BIG_ZERO

  return {
    vesting: vestingCrss.toJSON(),
    claimable: claimableCrss.toJSON(),
    withdrawable: withdrawableCrss.toJSON(),
    claimed: claimedCrss.toJSON(),
    totalCrssOwed: totalCrss.toJSON(),
    totalUserWithdrawn: withdrawnCrss.toJSON()
  }
}

export default fetchCompensationData
